import React, { useState, useEffect } from 'react';
import { withAdminLayout } from '../../layout';
import { withAppContext } from '../../components/withAppContext';
import {
  adminApiSafeSearchList,
  adminApiSafeSearchDelete,
  adminApiSafeSearchUpdate,
} from '../../util/adminApi';
import { SafeSearchTable } from '../../components/SafeSearchTable/Index';
import { CreateSafeSearch } from '../../components/SafeSearchTable/Create-SafeSearch';
import { FormattedMessage } from 'react-intl';

const AdminSafeSearch = props => {
  // Setting state
  const [items, setItems] = useState({ childrenData: [] });
  const [showCreate, setShowCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // get data from api
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const result = await adminApiSafeSearchList();
      setItems(result);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  // CRUD operations
  const addItem = item => {
    setItems([...items, item]);
  };

  const deleteItem = async id => {
    await adminApiSafeSearchDelete(id);
    setItems(items.filter(item => item.id !== id));
  };

  const updateItem = async (id, updatedItem) => {
    const result = await adminApiSafeSearchUpdate(updatedItem);
    setItems(items.map(item => (item.id === id ? result : item)));
  };

  return (
    <>
      <h2><FormattedMessage id="dashboard.safesearch list" /></h2>
      {showCreate ? (
        <CreateSafeSearch showCreate={setShowCreate} addItem={addItem} />
      ) : (
        <button className="btn" onClick={() => setShowCreate(true)}>
          <FormattedMessage id="dashboard.add safesearch" />
        </button>
      )}
      <br />
      <br />
      <SafeSearchTable
        items={items}
        updateItem={updateItem}
        deleteItem={deleteItem}
        isLoading={isLoading}
      />
    </>
  );
};

const customProps = {
  withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(AdminSafeSearch));
