import React, { useEffect, useState } from 'react';

import withLayout from '../../layout';
import { apiGetDynDns } from '../../util/api';
import { FormattedMessage } from 'react-intl';
import { AccessibilityMenu } from '../../components/AccessibilityMenu/AccessibilityMenu';

const DynDNSPage = () => {
  const [dyndns, setDyndns] = useState({});

  useEffect(() => {
    (async () => {
      const data = await apiGetDynDns();
      setDyndns(data);
    })();
  }, []);

  return (
    <div
      className="container"
      style={{
        margin: `0 auto`,
      }}
    >
      <AccessibilityMenu i18nKey="dyndns" />
      <p>
        <strong>
          <FormattedMessage id='dyndns.description' />
        </strong>
      </p>
      <p>
        <FormattedMessage id='dyndns.description2' />
      </p>
      <table className="table">
        <tbody>
          {dyndns.domains &&
            dyndns.domains.map(({ name, domain }, index) => (
              
              <tr key={`${name}--${domain}`}>
                <td>DynDNS Dienst für {(index === 0) ? 'das Hauptprofil' : name}</td>
                {/* @Todo: add jusprog url to config */}
                <td><a href={`https://login.jusprogdns.com/dyndns?domain=${domain}&loginname=${dyndns.loginname}&password=${dyndns.password}`} target='_blank'>{`https://login.jusprogdns.com/dyndns?domain=${domain}&loginname=${dyndns.loginname}&password=${dyndns.password}`}</a></td>
              </tr>
            ))}
        
        </tbody>
      </table>
      <p><strong><FormattedMessage id='dyndns.headline' /></strong></p>
      <p><FormattedMessage id='dyndns.additionalInfo' /></p>
      {Boolean(dyndns) && (
        <table className="table">
          <tbody>
            <tr>
              <td><FormattedMessage id='dyndns.url_label' /></td>
              <td>
              <FormattedMessage id='dyndns.url' />
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id='dyndns.login' /></td>
              <td>{dyndns.loginname}</td>
            </tr>
            <tr>
              <td><FormattedMessage id='dyndns.password' /></td>
              <td>{dyndns.password}</td>
            </tr>
            <tr>
              <td><FormattedMessage id='dyndns.domains' /></td>
              <td>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th><FormattedMessage id='dyndns.user_child' /></th>
                      <th><FormattedMessage id='dyndns.domain' /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dyndns.domains &&
                      dyndns.domains.map(({ name, domain }) => (
                        <tr key={`${name}--${domain}`}>
                          <td>{name}</td>
                          <td>{domain}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <p><strong><FormattedMessage id='dyndns.headline2' /></strong></p>
      <p><FormattedMessage id='dyndns.additionalInfo2_1' /></p>
      <p>https://login.jusprogdns.com/dyndns?myip=&lt;IP&gt;&amp;domain=&lt;domain&gt;&amp;loginname=&lt;loginname&gt;&amp;password=&lt;password&gt;</p>
      <p><FormattedMessage id='dyndns.additionalInfo2_2' /></p>
      {dyndns && dyndns.domains && <p key={`${dyndns.domains[0].name}--${dyndns.domains[0].domain}`}>
                {`https://login.jusprogdns.com/dyndns?myip=<IP>&domain=${dyndns.domains[0].domain}&loginname=${dyndns.loginname}&password=${dyndns.password}`}
      </p>}
      <p><FormattedMessage id='dyndns.additionalInfo2_3' /></p>
    </div>
  );
};

const customProps = {
  localeKey: 'home', // same as file name in src/i18n/translations/your-lang/index.js
  withAuth: true,
  title: <FormattedMessage id="dyndns.title" />,
};

export default withLayout(customProps)(DynDNSPage);
