import React, { Component, createContext } from "react";
import { apiFetchProfile } from "../util/api";

const defaultState = {
  user: undefined,
  changeUserState: () => {},
};

const AppContext = createContext(defaultState);

class AppProvider extends Component {
  state = {
    user: undefined,
  };

  changeUserState = (user) => {
    this.setState({ user });
  };

  async componentDidMount() {
    if (!this.state.user) {
      const user = await apiFetchProfile("me");
      this.setState({ user });
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          user: this.state.user,
          changeUserState: this.changeUserState,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export { AppContext, AppProvider };
