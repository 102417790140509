import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import withLayout from '../../layout';
import { withAppContext } from '../../components/withAppContext';

import ItemTable from '../../components/ItemTable/ItemTable';
import { apiFetchDomains, apiRemoveDomain, apiUpdateDomain } from '../../util/api';
import { Explainer } from '../../components/Explainer/Explainer';
import { AccessibilityMenu } from '../../components/AccessibilityMenu/AccessibilityMenu';

const BlacklistPage = props => {
  // Setting state
  const [items, setItems] = useState({ itemsData: [] });
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // get data from api
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const result = await apiFetchDomains('blacklist');
      setItems(result);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  // CRUD operations
  const addItem = item => {
    setItems([...items, item]);
  };

  const deleteItem = async id => {
    await apiRemoveDomain(id);
    setEditing(false);
    setItems(items.filter(item => item.id !== id));
  };

  const updateItem = async (id, updatedItem, initialValue) => {
    let result = await apiUpdateDomain(updatedItem);
    setEditing(false);

    if (result === undefined) {
      result = updatedItem;
      result.domain = initialValue;
    }

    setItems(items.map(item => (item.id === id ? result : item)));
  };

  const { formatMessage } = props.intl;

  return (
    <>
    <AccessibilityMenu i18nKey="blacklist" />
      <Explainer>
        <FormattedMessage id="filterlist.info" />
      </Explainer>
      <ItemTable
        itemTitle={formatMessage({id: 'filterlist.domain'})}
        itemName="Domain"
        items={items}
        addItem={addItem}
        updateItem={updateItem}
        deleteItem={deleteItem}
        editing={editing}
        isLoading={isLoading}
        block={true}
      />
    </>
  );
};

const customProps = {
  localeKey: 'list',
  withAuth: true,
  title: <FormattedMessage id="filterlist.title_blacklist" />,
};

export default withLayout(customProps)(withAppContext(injectIntl(BlacklistPage)));
