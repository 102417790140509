import React, { useState } from 'react';

import { withAppContext } from '../withAppContext';
import { Input } from '../Form/Input';
import { adminApiSafeSearchAdd } from '../../util/adminApi';
import { ButtonAdd } from '../Buttons/ButtonAdd';
import { Checkbox } from '../Form/Checkbox';

const Create = props => {
  /**
   * @type {{id: number, name: string, cname: string, forced: boolean }} initialFormState
   */
  const initialFormState = { id: null, domain: '', cname: '', forced: true };
  const [item, setItem] = useState(initialFormState);

  /**
   *
   * @param {(import('react').ChangeEvent<HTMLInputElement>} event
   */
  const handleInputChange = event => {
    const { name, value } = event.target;

    let setValue = value;

    if (name === 'forced') {
      setValue = !item.forced;
    }

    setItem({ ...item, [name]: setValue });
  };

  /**
   *
   * @param {import('react').FormEvent} event
   */
  const handleSubmit = async event => {
    event.preventDefault();

    if (!item.domain || !item.cname) return;
    const newItem = await adminApiSafeSearchAdd(item);
    props.addItem(newItem);
    setItem(initialFormState);
    props.showCreate(false);
  };

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <Input label="Domain" name="domain" value={item.domain} handleChange={handleInputChange} />
      <Input label="CNAME" name="cname" value={item.cname} handleChange={handleInputChange} />
      <Checkbox
        name="forced"
        label="Aktiv?"
        defaultChecked={item.forced}
        onClick={handleInputChange}
      />
      <br />
      <div className="form-group">
        <ButtonAdd />
      </div>
    </form>
  );
};

const CreateSafeSearch = withAppContext(Create);

export { CreateSafeSearch };
