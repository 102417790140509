import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import withLayout from "../../layout";
import { withAppContext } from "../../components/withAppContext";

import { Input } from "../../components/Form/Input";
import { InputEmail } from "../../components/Form/InputEmail";
import {
  apiFetchProfile,
  apiUpdateProfile,
  deleteProfile,
} from "../../util/api";
import { registerTypes } from "../../components/Register";
import { Select } from "../../components/Form/Select";
import { AccessibilityMenu } from "../../components/AccessibilityMenu/AccessibilityMenu";

class ProfilePage extends Component {
  initialState = {
    type: registerTypes[0],
    ...this.props.appContext.user,
    password: "",
    confirmPassword: "",
    modalOpen: false,
    deletePassword: "",
    deleteLoading: false,
    deleteError: "",
  };

  state = {
    ...this.initialState,
  };

  async componentDidMount() {
    const newProfile = await apiFetchProfile(
      this.props.appContext.user.username,
    );
    this.setState({ ...newProfile });
  }

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  handleChange = (event) => {
    // update interal state as we type
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * @param {import('react').FormEvent} event
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const { password, confirmPassword, ...profile } = this.state;

    if (password.length > 4 && password === confirmPassword) {
      profile.password = password;
      this.setState({ passwordChange: true });
    }

    const updatedUser = await apiUpdateProfile(profile);
    this.props.appContext.changeUserState(updatedUser);
    this.setState({ ...updatedUser, isEditing: false, newip: "" });
  };

  /**
   * @param {import('react').FormEvent} event
   */
  handleReset = (event) => {
    event.preventDefault();
    this.setState(() => this.initialState);
  };

  render() {
    const {
      username,
      name,
      email,
      password,
      confirmPassword,
      type,
      modalOpen,
      deletePassword,
      id,
    } = this.state;

    const { formatMessage } = this.props.intl;

    return (
      <>
        <AccessibilityMenu i18nKey="profile" />
        <form
          method="post"
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
        >
          <Input
            type="text"
            name="username"
            label={formatMessage({ id: "profile.username" })}
            placeholder=" "
            value={username}
            handleChange={this.handleChange}
          />
          <Input
            type="text"
            name="name"
            label={formatMessage({ id: "profile.name" })}
            placeholder=" "
            value={name}
            handleChange={this.handleChange}
          />
          <InputEmail
            name="email"
            label={formatMessage({ id: "profile.email" })}
            value={email}
            handleChange={this.handleChange}
          />
          <div className="form-group">
            <label className="form-label">
              <FormattedMessage id="profile.i_am_we_are" />
            </label>
            <Select
              name="type"
              handleChange={this.handleChange}
              options={registerTypes}
              value={type}
            />
          </div>
          <br />
          <hr />
          <Input
            type="password"
            name="password"
            label={formatMessage({ id: "profile.password" })}
            placeholder=" "
            value={password}
            handleChange={this.handleChange}
          />
          <Input
            type="password"
            name="confirmPassword"
            label={formatMessage({ id: "profile.repeat_password" })}
            placeholder=" "
            value={confirmPassword}
            handleChange={this.handleChange}
          />
          <br />
          <span>
            {this.state.passwordChange ? "Benutzer aktualisiert" : ""}
          </span>
          <br />
          <button className="btn mr-2" type="reset">
            <FormattedMessage id="profile.reset" />
          </button>
          <button className="btn btn-primary ml-2" type="submit">
            <FormattedMessage id="profile.save" />
          </button>

          <button
            type="button"
            className="btn btn-error"
            style={{ float: "right" }}
            onClick={() =>
              this.setState({
                modalOpen: true,
                deletePassword: "",
                deleteError: "",
              })}
          >
            <FormattedMessage id="profile.delete" />
          </button>
        </form>

        <div
          className={`modal modal-sm${modalOpen ? " active" : ""}`}
          id="modal-id"
        >
          <div
            className="modal-overlay"
            aria-label="Close"
            onClick={() => this.setState({ modalOpen: false })}
          />
          <div className="modal-container">
            <div className="modal-header">
              <button
                className="btn btn-clear float-right"
                aria-label="Close"
                onClick={() => this.setState({ modalOpen: false })}
              />
              <div className="modal-title h5">
                <FormattedMessage id="profile.delete_modal_title" />
              </div>
            </div>
            <div className="modal-body">
              <div className="content">
                <FormattedMessage id="profile.delete_modal_content" />
              </div>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  this.setState({ deleteLoading: true, deleteError: "" });
                  const status = await deleteProfile(deletePassword, id);
                  if (status === 200) {
                    this.setState({
                      deleteError: formatMessage({
                        id: "profile.delete_success",
                      }),
                    });

                    return setTimeout(() => {
                      logout(() => {
                        window.location.reload();
                      });
                    }, 2000);
                  }

                  if (status === 403) {
                    return this.setState({
                      deleteLoading: false,
                      deleteError: formatMessage({
                        id: "profile.delete_error_password",
                      }),
                    });
                  }

                  this.setState({
                    deleteLoading: false,
                    deleteError: formatMessage({
                      id: "profile.delete_unknown",
                    }),
                  });
                }}
              >
                <Input
                  type="password"
                  name="password"
                  label={formatMessage({ id: "profile.delete_modal_label" })}
                  placeholder=""
                  value={deletePassword}
                  handleChange={(e) => {
                    this.setState({ deletePassword: e.target.value });
                  }}
                />
                {this.state.deleteError && (
                  <>
                    <div className="toast toast-error">
                      {this.state.deleteError}
                    </div>
                    <br />
                  </>
                )}
                <button
                  type="submit"
                  className={`btn btn-error${
                    this.state.deleteLoading ? " loading" : ""
                  }`}
                  style={{ width: "100%" }}
                  disabled={this.state.deleteLoading}
                >
                  <FormattedMessage id="profile.delete_modal_button" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const customProps = {
  withAuth: true,
  title: <FormattedMessage id="profile.title" />,
};

export default withLayout(customProps)(withAppContext(injectIntl(ProfilePage)));
