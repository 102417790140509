import axios from "axios";

import {
  USER_ENDPOINT,
  SAFESEARCH_ENDPOINT,
  PORTAL_ENDPOINT,
  LOGFILE_ENDPOINT,
  USERQUOTA_ENDPOINT,
} from "./api";

/**
 * @typedef {{ fieldname: string, direction: 'ASC' | 'DESC' }} FieldSorting
 */

/**
 * Get the user profile
 *
 * @param {number} limit
 * @param {number} offset
 * @param {Array<FieldSorting>} [sorting]
 */
const adminApiUserlist = async (
  itemsPerPage,
  currentPage,
  sorting,
  search,
  type,
  quota,
  active
) => {
  const searchParams = new URLSearchParams();
  if (sorting.length > 0) {
    searchParams.set("sorting", JSON.stringify(sorting));
  }
  searchParams.set("limit", itemsPerPage);
  searchParams.set("offset", Math.max(0, (currentPage - 1) * itemsPerPage));
  searchParams.set("search", search);
  searchParams.set("type", type);
  searchParams.set("quotaStateType", quota);
  searchParams.set("active", active);
  try {
    let { status, data } = await axios({
      method: "get",
      responseType: "json",

      url: `${USER_ENDPOINT}?${searchParams.toString()}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiUserUpdate = async (updatedUser) => {
  try {
    let { status, data } = await axios({
      method: "patch",
      responseType: "json",
      data: {
        ...updatedUser,
        name: updatedUser.name ? updatedUser.name : updatedUser.username,
      },
      url: `${USER_ENDPOINT}/${updatedUser.id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchList = async () => {
  try {
    let { status, data } = await axios({
      method: "get",
      responseType: "json",

      url: `${SAFESEARCH_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchAdd = async (sendData) => {
  try {
    let { status, data } = await axios({
      method: "post",
      responseType: "json",

      data: sendData,
      url: `${SAFESEARCH_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchUpdate = async (sendData) => {
  try {
    const { id, ...rest } = sendData;

    let { status, data } = await axios({
      method: "patch",
      responseType: "json",
      data: rest,
      url: `${SAFESEARCH_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchDelete = async (id) => {
  try {
    let { status, data } = await axios({
      method: "delete",
      responseType: "json",
      url: `${SAFESEARCH_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiPortalList = async () => {
  try {
    let { status, data } = await axios({
      method: "get",
      responseType: "json",
      url: `${PORTAL_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiPortalAdd = async (sendData) => {
  try {
    let { status, data } = await axios({
      method: "post",
      responseType: "json",
      data: sendData,
      url: `${PORTAL_ENDPOINT}`,
    });

    if (status === 201) return data;
    else {
      console.log("Unexpected response status: ", status, data);
      return null;
    }
  } catch (error) {
    return null;
  }
};

const adminApiPortalUpdate = async (sendData) => {
  try {
    const { id, ...rest } = sendData;

    let { status, data } = await axios({
      method: "patch",
      responseType: "json",
      data: rest,
      url: `${PORTAL_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiPortalDelete = async (id) => {
  try {
    let { status, data } = await axios({
      method: "delete",
      responseType: "json",
      url: `${PORTAL_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiLogfile = async () => {
  try {
    let { status, data } = await axios({
      method: "get",
      responseType: "json",
      url: `${LOGFILE_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiUserQuota = async (sendData) => {
  try {
    let { status, data } = await axios({
      method: "post",
      responseType: "json",
      data: sendData,
      url: `${USERQUOTA_ENDPOINT}`,
    });

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 error
    } else if (error.response && error.response.status === 404) {
      throw new Error("404");
    } else {
      throw error;
    }
  }
};

export {
  adminApiUserlist,
  adminApiUserUpdate,
  adminApiSafeSearchList,
  adminApiSafeSearchAdd,
  adminApiSafeSearchDelete,
  adminApiSafeSearchUpdate,
  adminApiPortalList,
  adminApiPortalAdd,
  adminApiPortalDelete,
  adminApiPortalUpdate,
  adminApiLogfile,
  adminApiUserQuota,
};
