import React, { Component } from 'react';
import { withAdminLayout } from '../../layout';
import { withAppContext } from '../../components/withAppContext';
import { adminApiLogfile, adminApiUserUpdate } from '../../util/adminApi';
import { AdminUserTable } from '../../components/AdminUserTable/Index';
import { FormattedMessage } from 'react-intl';
// import { Pagination } from './pagination';

class AdminUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logfile: undefined
    };
  }

  fetchApiLogfile = () => {
    return adminApiLogfile(
    );
  };

  async componentDidMount() {
    const response = await this.fetchApiLogfile();
    if(response != undefined) {
      this.setState({ logfile: [...response] })
    }
  }

  render() {
    return (
      <>
        <h1 className="admin">
          <FormattedMessage id="dashboard.Admin Logfile" />
        </h1>
        {this.state.logfile &&
          <ul> {
          this.state.logfile.map((line,key) => line.length ? <li key={key}>{line}</li> : undefined)
          }
          </ul>}
        {/* <Pagination currentPage={this.state.currentPage} totalItems={this.state.totalUsers} /> */}
      </>
    );
  }
}

const customProps = {
  withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(AdminUser));
