import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { isIpValid } from "../../util/validation";
import { Input } from "../Form/Input";
import { IpInput } from "../Form/IpInput";
import { PortalSettings } from "../PortalSettings/portal-settings";
import { v4 as uuid } from "uuid";
import { apiFetchChild } from "../../util/api.js";
import { FormattedMessage } from "react-intl";
import { adminApiPortalList } from "../../util/adminApi.js";

const Item = ({
  item: initialItem,
  updateItem = () => void 0,
  deleteItem = () => void 0,
  age: globalAge,
}) => {
  const [effectCounter, setEffectCounter] = useState(0);
  const [child, setChild] = useState({ ...initialItem });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: child?.name,
      age: child?.age,
      ageXml: child?.ageXml,
      apiOnTheFly: child?.apiOnTheFly,
    },
  });

  const [ips, setIps] = useState(
    child.ips
      .filter(({ dyndns }) => !dyndns)
      .map((ip) => ({
        ...ip,
        uuid: uuid(),
      })),
  );

  const [dynamicIps, setDynamicIps] = useState(
    child.ips
      .filter(({ dyndns }) => dyndns)
      .map((ip) => ({
        ...ip,
        uuid: uuid(),
      })),
  );

  const [isEditing, setIsEditing] = useState(false);
  const [portals, setPortals] = useState(child.portals);
  const [availablePortals, setAvailablePortals] = useState([]);

  useEffect(() => {
    (async () => {
      const child = await apiFetchChild(initialItem.id);
      setAvailablePortals(await adminApiPortalList());

      setChild(child);

      setValue("name", child.name);
      setValue("age", child.age);
      setValue("ageXml", child.ageXml);
      setValue("apiOnTheFly", child.apiOnTheFly);
      setIps(
        child.ips
          .filter(({ dyndns }) => !dyndns)
          .map((ip) => ({
            ...ip,
            uuid: uuid(),
          })),
      );
      setDynamicIps(
        child.ips
          .filter(({ dyndns }) => dyndns)
          .map((ip) => ({
            ...ip,
            uuid: uuid(),
          })),
      );
    })();
  }, [
    initialItem,
    setChild,
    setValue,
    setIps,
    setDynamicIps,
    setAvailablePortals,
    effectCounter,
  ]);

  const handleCheckedChange = ({ target: { name } }) => {
    setChild((prevItem) => ({ ...prevItem, [name]: !prevItem[name] }));
  };

  const submit = async ({ name, age, ageXml, apiOnTheFly }) => {
    setIsEditing(false);

    await updateItem(child.id, {
      ...child,
      name,
      age,
      ips: ips.filter(({ ip }) => !!ip.trim()),
      portals,
      ageXml,
      apiOnTheFly,
    });

    setEffectCounter((old) => old + 1);
  };

  const updatePortals = (portal, updates) => {
    const updatedPortals = portals.map((p) =>
      p.name === portal.name ? { ...p, ...updates } : p
    );
    setPortals(updatedPortals);
  };

  const renderPortals = () =>
    availablePortals.map((portal) => {
      const currentPortal = portals.find((p) => p.name === portal.name) || {};
      return (
        <PortalSettings
          key={portal.name}
          name={portal.name}
          active={currentPortal.active}
          safeSearch={currentPortal.safeSearch}
          hasSafeSearch={portal.safeSearches.length > 0}
          globalAge={globalAge}
          text={portal.text}
          disabled={false}
          onActiveToggle={() => {
            updatePortals(portal, { active: !currentPortal.active });
          }}
          onSafeSearchToggle={() => {
            updatePortals(portal, { safeSearch: !currentPortal.safeSearch });
          }}
        />
      );
    });

  return (
    <>
      {isEditing
        ? (
          <tr>
            <td colSpan={5}>
              <form onSubmit={handleSubmit(submit)}>
                <div className="form-group">
                  <div className="form-group">
                    <h4>
                      <label className="form-label">Name</label>
                    </h4>
                    <input
                      className="form-input"
                      type={"text"}
                      name={"name"}
                      placeholder={" "}
                      {...register("name")}
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                    }}
                  >
                    <h4>
                      <label>Alter</label>
                    </h4>
                    <select
                      name="age"
                      {...register("age")}
                    >
                      {[0, 6, 12, 16, 18].map((age) => (
                        <option key={age} value={age}>{`ab ${age}`}</option>
                      ))}
                    </select>
                  </div>

                  {dynamicIps.length > 0 && (
                    <div className="form-group">
                      <h4>
                        <label>DynDNS IPs</label>
                      </h4>
                      {dynamicIps.map((ip) => (
                        <span
                          key={ip.uuid}
                          className="tooltip"
                          data-tooltip="Dynamische IP\nnicht änderbar"
                          style={{
                            fontStyle: "italic",
                            opacity: 0.7,
                            cursor: "default",
                          }}
                        >
                          <IpInput
                            ip={ip}
                            style={{
                              width: "100%",
                              marginRight: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            handleChange={(e) => void 0}
                            isEditing={false}
                          />
                        </span>
                      ))}
                    </div>
                  )}

                  <div
                    className="form-group"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h4>
                      <label>Static IPs</label>
                    </h4>
                    {ips.map((ip) => (
                      <span
                        key={ip.uuid}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          rowGap: "10px",
                        }}
                      >
                        <IpInput
                          ip={ip}
                          style={{
                            width: "100%",
                            marginRight: "10px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          handleChange={(e) => {
                            const value = e.target.value;
                            setIps((old) =>
                              old.map(({ uuid, ...rest }) => {
                                if (uuid === ip.uuid) {
                                  return { uuid, ...rest, ip: value };
                                }
                                return { uuid, ...rest };
                              })
                            );
                          }}
                          isEditing={true}
                        />
                        <button
                          type="button"
                          className="btn btn-error btn-sm"
                          style={{ paddingBlock: "5px", height: "100%" }}
                          onClick={() =>
                            setIps((old) =>
                              old.filter(({ uuid }) => uuid !== ip.uuid)
                            )}
                        >
                          Delete
                        </button>
                      </span>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        setIps((
                          old,
                        ) => [...old, { ip: "", dyndns: false, uuid: uuid() }])}
                    >
                      Add
                    </button>
                  </div>

                  <h4>
                    <FormattedMessage id="profile.uses" />
                  </h4>
                  <div className="form-group">
                    <br />
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="ageXml"
                        {...register("ageXml")}
                      />
                      <FormattedMessage id="settings.use_age_xml" />
                    </label>
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      <input
                        type="checkbox"
                        name="apiOnTheFly"
                        {...register("apiOnTheFly")}
                      />
                      <FormattedMessage id="settings.use_otf" />
                    </label>
                  </div>

                  {availablePortals.length > 0 && (
                    <>
                      <h3>Online-Portale</h3>
                      {renderPortals()}
                    </>
                  )}

                  <button className="btn btn-primary" type="submit">
                    <i className="icon icon-check" />
                  </button>
                </div>
              </form>
            </td>
          </tr>
        )
        : (
          <tr>
            <td>
              <input
                type="checkbox"
                name={child.id}
                id={child.id}
                onChange={handleCheckedChange}
              />
            </td>
            <td>{child.name}</td>
            <td>{`ab ${child.age}`}</td>
            <td>
              {child.ips.map((ip, index) => (
                <span
                  key={`ip-${ip.id}`}
                  className={ip.dyndns ? "tooltip" : undefined}
                  data-tooltip={ip.dyndns
                    ? "Dynamische IP\nnicht änderbar"
                    : undefined}
                  style={{
                    fontStyle: ip.dyndns ? "italic" : "initial",
                    opacity: ip.dyndns ? 0.7 : 1,
                    cursor: "default",
                  }}
                >
                  {ip.ip}
                  {index < child.ips.length - 1 ? ", " : ""}
                </span>
              ))}
            </td>
            <td>
              {child.portals &&
                ((portals) => {
                  return portals.map((portal, index) => (
                    <span key={`portal-${portal.name}`}>
                      {portal.name}
                      {index < portals.length - 1 ? ", " : ""}
                    </span>
                  ));
                })(child.portals.filter(({ active }) => active))}
            </td>
            <td style={{ textAlign: "right" }}>
              {updateItem && (
                <button
                  className="btn btn-default btn-sm mr-2"
                  title="Bearbeiten"
                  onClick={() => setIsEditing(true)}
                >
                  <i className="icon icon-edit" />
                </button>
              )}
              {deleteItem && (
                <button
                  className="btn btn-error btn-sm"
                  title="Löschen"
                  onClick={() =>
                    window.confirm("Sind Sie sicher?") && deleteItem(child.id)}
                >
                  <i className="icon icon-delete" />
                </button>
              )}
            </td>
          </tr>
        )}
    </>
  );
};

export { Item };
