import React, { useState } from "react";
import { ButtonEdit } from "../Buttons/ButtonEdit";
import { ButtonDelete } from "../Buttons/ButtonDelete";
import { ButtonSave } from "../Buttons/ButtonSave";
import { Input } from "../Form/Input";
import { injectIntl } from "react-intl";
import { SafeSearch } from "./SafeSearch";

const ItemIntl = (props) => {
  const [item, setItem] = useState(props.item);
  const [isEditing, setIsEditing] = useState(false);
  const { formatMessage } = props.intl;

  /**
   * @param {import('react').FormEvent} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!item.domains || !item.name) return;

    setIsEditing(false);

    let { domains, ...rest } = item;

    props.updateItem(item.id, item);

    setItem({ domains: domains.split("\n").join(","), ...rest });
  };

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>|import('react').ChangeEvent<HTMLSelectElement>} event
   */
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let setValue = value;

    setItem({ ...item, [name]: setValue });
  };

  return (
    <>
      {isEditing
        ? (
          <tr>
            <td colSpan={5}>
              <form method="post" onSubmit={handleSubmit}>
                <Input
                  name="name"
                  label="Name"
                  value={item.name}
                  handleChange={handleInputChange}
                />

                <textarea
                  name="domains"
                  label="Domain"
                  value={item.domains.split(",").join("\n")}
                  onChange={handleInputChange}
                />
                <Input
                  name="text"
                  label="Text"
                  value={item.text}
                  handleChange={handleInputChange}
                />

                <div className={`form-group`}>
                  <label className="form-label" htmlFor="age-selector">
                    Alter
                  </label>
                  <select
                    name="age"
                    className="form-select"
                    id="age-selector"
                    onChange={handleInputChange}
                    defaultValue={item.age ? item.age.toString() : undefined}
                  >
                    <option value="0">
                      {formatMessage({ id: "children.ages.0" })}
                    </option>
                    <option value="6">
                      {formatMessage({ id: "children.ages.6" })}
                    </option>
                    <option value="12">
                      {formatMessage({ id: "children.ages.12" })}
                    </option>
                    <option value="16">
                      {formatMessage({ id: "children.ages.16" })}
                    </option>
                    <option value="18">
                      {formatMessage({ id: "children.ages.18" })}
                    </option>
                  </select>
                </div>
                <div className={`form-group`}>
                  <h4>SafeSearches</h4>
                  <div className="columns">
                    <div className="column col-6">
                      <h5>Domain</h5>
                    </div>
                    <div className="column col-6">
                      <h5>CNAME</h5>
                    </div>
                  </div>
                  {item.safeSearches.length > 0 &&
                    item.safeSearches.map((value, index) => {
                      return (
                        <SafeSearch
                          key={`safesearch-${index}`}
                          index={index}
                          {...value}
                          onChange={(value, index) => {
                            item.safeSearches[index] = value;
                            setItem({ ...item });
                          }}
                        />
                      );
                    })}
                  <button
                    type="button"
                    onClick={() => {
                      item.safeSearches.push(undefined);
                      setItem({ ...item });
                    }}
                  >
                    addSafesearch
                  </button>
                </div>
                <ButtonSave showLabel={true} />
              </form>
            </td>
          </tr>
        )
        : (
          <tr>
            <td>{item.name}</td>
            <td>{item.domains}</td>
            <td>{item.safeSearches.length}</td>
            <td>{item.age}</td>
            <td>{item.text}</td>
            <td style={{ textAlign: `right`, width: 80 }}>
              <ButtonEdit
                onClick={(event) => {
                  event.preventDefault();
                  setIsEditing(true);
                }}
              />
              <ButtonDelete
                onClick={() =>
                  window.confirm("Sind Sie sicher?") &&
                  props.deleteItem(item.id)}
              />
            </td>
          </tr>
        )}
    </>
  );
};

const Item = injectIntl(ItemIntl);

export { Item };
