import React, { useEffect, useState } from "react";
import { withAdminLayout } from "../../layout";
import { withAppContext } from "../../components/withAppContext";
import {
  adminApiPortalDelete,
  adminApiPortalList,
  adminApiPortalUpdate,
} from "../../util/adminApi";
import { PortalTable } from "../../components/PortalTable/Index";
import { CreatePortal } from "../../components/PortalTable/Create-Portal";
import { FormattedMessage } from "react-intl";

const AdminPortal = (props) => {
  // Setting state
  const [items, setItems] = useState({ childrenData: [] });
  const [showCreate, setShowCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // get data from api
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const result = await adminApiPortalList();
      setItems(result);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  // CRUD operations
  const addItem = (item) => {
    setItems((old) => [...old, item]);
  };

  const deleteItem = async (id) => {
    await adminApiPortalDelete(id);
    setItems(items.filter((item) => item.id !== id));
  };

  const updateItem = async (id, updatedItem) => {
    const result = await adminApiPortalUpdate(updatedItem);
    setItems(items.map((item) => (item.id === id ? result : item)));
  };

  return (
    <>
      <h2>
        <FormattedMessage id="dashboard.portal list" />
      </h2>
      {showCreate
        ? <CreatePortal showCreate={setShowCreate} addItem={addItem} />
        : (
          <button className="btn" onClick={() => setShowCreate(true)}>
            <FormattedMessage id="dashboard.add portal" />
          </button>
        )}
      <br />
      <br />
      <PortalTable
        items={items}
        updateItem={updateItem}
        deleteItem={deleteItem}
        isLoading={isLoading}
      />
    </>
  );
};

const customProps = {
  withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(AdminPortal));
